<script>
import MasterDetail from "@/components/master-detail.vue";
import { mapGetters } from "vuex";

// TODO precisa pegar o enum do backend
const TipoParticipanteEnum = {
  COLABORADOR: 0,
  VAGA_ABERTA: 1,
  TERCEIRO: 2,
  VAGA_NOVA: 3,
};

export default {
  components: {
    MasterDetail
  },
  data() {
    return {
      opts: {
        perfis: [],
        tipoParticipantes: [
          {
            id: TipoParticipanteEnum.COLABORADOR,
            value: "Colaborador interno"
          },
          {
            id: TipoParticipanteEnum.VAGA_ABERTA,
            value: "Vagas em aberto"
          },
          {
            id: TipoParticipanteEnum.TERCEIRO,
            value: "Terceiros"
          },
          {
            id: TipoParticipanteEnum.VAGA_NOVA,
            value: "Novas vagas"
          },
        ],
        statusRubrica: [
          {
            id: 1,
            value: "Ativo"
          },
          {
            id: 0,
            value: "Inativo"
          }
        ]
      },
      isVisibleModalParticipante: false,
      modalParticipanteValue: {},
      selectedItem: {},
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    cols() {
      const { tipo } = this.selectedItem;
      const that = this;

      return [
        {
          key: "tipo",
          name: "Tipo de participante",
          type: this.$fieldTypes.RADIO,
          hideInTable: true,
          rules: [{ rule: "required" }],
          rel: { to: "tipoParticipantes", id: "id", name: "value" },
          colSize: 6,
        },
        {
          key: "identificador",
          name: "Identificador da vaga",
          type: this.$fieldTypes.TEXT,
          hideInTable: true,
          hideInform: tipo !== TipoParticipanteEnum.VAGA_ABERTA,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "matricula",
          name: "Matrícula",
          type: this.$fieldTypes.TEXT,
          hideInform: tipo !== TipoParticipanteEnum.COLABORADOR,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "nome",
          name: "Nome participante",
          type: this.$fieldTypes.TEXT,
          hideInform: ![TipoParticipanteEnum.COLABORADOR, TipoParticipanteEnum.TERCEIRO].includes(tipo),
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "perfil",
          name: "Senioridade",
          type: this.$fieldTypes.SELECT,
          hideInTable: true,
          rel: { to: "perfis", id: "id", name: "perfil" },
          rules: [{ rule: "required" }],
          colSize: 6,
          valueChanged: (perfilId) => {
            const perfil = that.opts.perfis.find(({ id }) => perfilId === id);

            if (perfil) {
              that.selectedItem.especialidade = perfil.especialidade;
              return;
            }

            that.selectedItem.especialidade = null;
          },
        },
        {
          key: "email",
          name: "E-mail",
          type: this.$fieldTypes.TEXT,
          hideInTable: true,
          hideInform: ![TipoParticipanteEnum.COLABORADOR, TipoParticipanteEnum.TERCEIRO].includes(tipo),
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "especialidade",
          name: "Especialidade",
          type: this.$fieldTypes.TEXT,
          hideInform: tipo === TipoParticipanteEnum.TERCEIRO,
          editable: false,
          colSize: 6,
        },
        {
          key: "habilidades",
          name: "Habilidades",
          hideInTable: true,
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "custo_medio",
          name: "Custo médio",
          hideInTable: true,
          type: this.$fieldTypes.TEXT,
          hideInform: tipo !== TipoParticipanteEnum.TERCEIRO,
          rules: [{ rule: "required" }],
          colSize: 6,
        },
        {
          key: "empresa",
          name: "Empresa",
          hideInTable: true,
          type: this.$fieldTypes.TEXT,
          hideInform: tipo !== TipoParticipanteEnum.TERCEIRO,
          rules: [{ rule: "required" }],
          colSize: 12,
        },
        {
          key: "formacao",
          name: "Formação técnica",
          type: this.$fieldTypes.TEXT,
          hideInform: ![TipoParticipanteEnum.COLABORADOR, TipoParticipanteEnum.VAGA_ABERTA].includes(tipo),
          colSize: 6,
        },
        {
          key: "rede",
          name: "LinkedIn",
          hideInTable: true,
          type: this.$fieldTypes.TEXT,
          hideInform: tipo !== TipoParticipanteEnum.COLABORADOR,
          rules: [{ rule: "required" }],
          colSize: 12,
        },
      ];
    },
    customResource() {
      const resource = this.apiResource(
        `/v1/ativacaoped/${this.clientId}/participantes`
      );
      return {
        ...resource,
        async get(...args) {
          let response = {};
          try {
            response = await resource.get(...args);
            const { data, error } = response;

            if (error) {
              throw error;
            }

            if (!Array.isArray(data)) {
              throw new Error("Data não encontrada");
            }

            response.data = data.map(({ matricula, ...rest }) => ({
              ...rest,
              matricula: matricula ?? "Sem matricula"
            }));
          } catch (error) {
            this.notify(error);
            response.data = [];
            response.error = null;
          }
          return response;
        }
      };
    }
  },
  created() {
    this.getPerfis();
  },
  methods: {
    doExport() {
      window.alert("TO DO Implementar");
    },
    doImport() {
      window.alert("TO DO Implementar");
    },
    async getPerfis() {
      try {
        const { get } = this.apiResource(`v1/ativacaoped/${this.clientId}/perfil/selecao`);
        this.perfis = await get();
      } catch (error) {
        this.perfis = [];
      }
    },
    onOpenFormDialog(value) {
      this.selectedItem = value ?? {};
      this.selectedItem.tipo = TipoParticipanteEnum.COLABORADOR;
    },
  }
};
</script>

<template>
  <MasterDetail
    ref="masterDetail"
    :canDelete="false"
    :canEdit="false"
    :cols="cols"
    class="mt-3"
    :customResource="customResource"
    disableContextMenu
    :formWidth="850"
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :opts="opts"
    serverPagination
    @onOpenFormDialog="onOpenFormDialog"
  >
    <div class="d-flex">
      <v-spacer></v-spacer>
      <div
        aria-label="exportButton"
        class="table-v-action-button mr-3 pt-1"
        @click="doExport()"
      >
        <v-icon>mdi-tray-arrow-down</v-icon> Exportar modelo
      </div>
      <div
        aria-label="importButton"
        class="table-v-action-button mr-3 pt-1"
        @click="doImport()"
      >
        <v-icon>mdi-upload-box</v-icon> Importações
      </div>
    </div>
  </MasterDetail>
</template>
