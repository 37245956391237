<script>
import MasterDetail from '@/components/master-detail.vue';
import { mapGetters } from 'vuex';
import { isObject } from 'lodash';

export default {
  components: {
    MasterDetail,
  },
  data() {
    return {
      opts: {},
    };
  },
  computed: {
    ...mapGetters(['clientId']),
    cols() {
      const months = [];

      // if () {
      //  TODO aqui implementar o teste de mostrar apenas os meses que tem retorno
      // }

      return [
        {
          key: "email",
          name: "E-mail",
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "senioridade",
          name: "Senioridade",
          type: this.$fieldTypes.TEXT,
        },
        ...months,
        {
          key: "competencia_ini",
          name: "Competência Início",
          type: this.$fieldTypes.MONTH,
          hideInTable: true,
          colSize: 6,
        },
        {
          key: "competencia_fim",
          name: "Competência Fim",
          type: this.$fieldTypes.MONTH,
          hideInTable: true,
          colSize: 6,
        },
        {
          key: "porcentagem",
          name: "Porcentagem",
          type: this.$fieldTypes.NUMBER,
          hideInTable: true,
        },
      ];
    },
    customResource() {
      const notify = (msg) => this.$notify({
        group: "geral",
        duration: 5000,
        type: "alert",
        title: "Mockado",
        text: msg,
      });
      return {
        async get(...args) {
          console.log('Alocação customResource GET', isObject(args) ? { ...args } : args);
          notify('GET não implementado');
          return [];
        },
        async save(...args) {
          console.log('Alocação customResource SAVE', isObject(args) ? { ...args } : args);
          notify('SAVE não implementado');
          return {};
        },
        async patch(...args) {
          console.log('Alocação customResource PATCH', isObject(args) ? { ...args } : args);
          notify('PATCH não implementado');
          return {};
        },
        async delete(...args) {
          console.log('Alocação customResource DELETE', isObject(args) ? { ...args } : args);
          notify('DELETE não implementado');
          return {};
        },
      }
    }
  },
}
</script>

<template>
  <MasterDetail
    ref="masterDetail"
    :canDelete="false"
    :canEdit="false"
    :cols="cols"
    class="mt-3"
    :customResource="customResource"
    :disableContextMenu="true"
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :hasFilter="false"
    :opts="opts"
    serverPagination
  ></MasterDetail>
</template>
