<script>
import MasterDetail from '@/components/master-detail.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    MasterDetail,
  },
  data() {
    return {
      opts: [],
    };
  },
  computed: {
    ...mapGetters(['clientId']),
    cols() {
      return [
        {
          key: "entregavel",
          name: "Entregável",
          hideInform: true,
          type: this.$fieldTypes.TEXT,
        },
        {
          key: "nome",
          name: "Nome",
          type: this.$fieldTypes.TEXT,
          colSize: 12,
        },
        {
          key: "descricao",
          name: "Descrição",
          type: this.$fieldTypes.TEXTAREA,
          colSize: 12,
        },
        {
          key: "data_inicio",
          name: "Data Início",
          type: this.$fieldTypes.MONTH,
          colSize: 6,
        },
        {
          key: "data_fim",
          name: "Data Fim",
          type: this.$fieldTypes.MONTH,
          colSize: 6,
        },
      ];
    },
    resourceUrl() {
      return `/v1/ativacaoped/${this.clientId}/roadmap`;
    }
  },
}
</script>

<template>
  <MasterDetail
    ref="masterDetail"
    :canDelete="false"
    :canEdit="false"
    :cols="cols"
    class="mt-3"
    :disableContextMenu="true"
    formTitle="Cadastro de entregáveis"
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :hasFilter="false"
    :opts="opts"
    :resourceUrl="resourceUrl"
    serverPagination
  ></MasterDetail>
</template>
