<script>
import MasterDetail from '@/components/master-detail.vue';
import { mapGetters } from 'vuex';
import { isObject } from 'lodash';

export default {
  components: {
    MasterDetail,
  },
  data() {
    return {
      opts: {
        acompanhamentos: [],
      },
    };
  },
  computed: {
    ...mapGetters(['clientId']),
    cols() {
      return [
        {
          key: "nome",
          name: "Nome do gasto",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
        },
        {
          key: "vinculacao",
          name: "Vinculação",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
        },
        {
          key: "descricao",
          name: "Descrição",
          type: this.$fieldTypes.TEXT,
          colSize: 6,
        },
        {
          key: "acompanhamento",
          name: "Acompanhamento",
          type: this.$fieldTypes.SELECT,
          rel: { to: "acompanhamentos", key: "id", name: "value" },
          colSize: 6,
        },
        {
          key: "valor",
          name: "Valor",
          type: this.$fieldTypes.MONEY,
          colSize: 6,
        },
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.MONTH,
          colSize: 6,
        },
      ];
    },
    customResource() {
      const notify = (msg) => this.$notify({
        group: "geral",
        duration: 5000,
        type: "alert",
        title: "Mockado",
        text: msg,
      });
      return {
        async get(...args) {
          console.log('Outras despesas customResource GET', isObject(args) ? { ...args } : args);
          notify('GET não implementado');
          return [];
        },
        async save(...args) {
          console.log('Outras despesas customResource SAVE', isObject(args) ? { ...args } : args);
          notify('SAVE não implementado');
          return {};
        },
        async patch(...args) {
          console.log('Outras despesas customResource PATCH', isObject(args) ? { ...args } : args);
          notify('PATCH não implementado');
          return {};
        },
        async delete(...args) {
          console.log('Outras despesas customResource DELETE', isObject(args) ? { ...args } : args);
          notify('DELETE não implementado');
          return {};
        },
      }
    }
  },
  created() {
    this.getAcompanhamentos();
  },
  methods: {
    async getAcompanhamentos() {
      try {
        // const { get } = this.apiResource(`v1/ativacaoped/${this.clientId}/acompanhamentos`);
        // this.opts.acompanhamentos = await get();
        this.opts.acompanhamentos = [
          {
            id: 0,
            value: 'Opção 1',
          },
          {
            id: 1,
            value: 'Opção 2',
          },
        ];
      } catch (error) {
        this.opts.acompanhamentos = [];
      }
    }
  }
}
</script>

<template>
  <MasterDetail
    ref="masterDetail"
    :canDelete="false"
    :canEdit="false"
    :cols="cols"
    class="mt-3"
    :customResource="customResource"
    :disableContextMenu="true"
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :hasFilter="false"
    :opts="opts"
    serverPagination
  ></MasterDetail>
</template>
