export const StatusBusinessPlanEnum = {
  "DEFINICAO": 1,
  "APROVACAO": 2,
  "ANDAMENTO": 3,
  "PRE_AMORTIZACAO": 4,
  "AMORTIZACAO": 5,
};

export const StatusBusinessPlanLabels = {
  [StatusBusinessPlanEnum.DEFINICAO]: "Em definição",
  [StatusBusinessPlanEnum.APROVACAO]: "Aprovação",
  [StatusBusinessPlanEnum.ANDAMENTO]: "Em andamento",
  [StatusBusinessPlanEnum.PRE_AMORTIZACAO]: "Pré-amortização",
  [StatusBusinessPlanEnum.AMORTIZACAO]: "Amortização",
  "DEFINICAO": "Em definição",
  "APROVACAO": "Aprovação",
  "ANDAMENTO": "Em andamento",
  "PRE_AMORTIZACAO": "Pré-amortização",
  "AMORTIZACAO": "Amortização",
};
