<template>
  <v-row class="h-100">
    <v-col cols="8">
      <v-card class="p-3">
        <v-item-group
          v-model="currentStep"
          class="text-center d-flex justify-content-between"
          mandatory
        >
          <v-item v-for="(label, step) in tabs" v-slot="{ active }" :key="step">
            <v-btn
              class="tab"
              :class="{ active }"
              dense
              text
              tile
              @click="currentStep = step"
            >
              {{ label }}
            </v-btn>
          </v-item>
        </v-item-group>
        <v-window v-model="currentStep">
          <v-window-item>
            <BPForm ref="BPForm" :data.sync="dadosBP" />
          </v-window-item>
          <v-window-item>
            <Discovery />
          </v-window-item>
          <v-window-item>
            <Roadmap />
          </v-window-item>
          <v-window-item>
            <Participantes />
          </v-window-item>
          <v-window-item>
            <Alocacao />
          </v-window-item>
          <v-window-item>
            <OutrasDespesas />
          </v-window-item>
          <v-window-item>
            <p>VPL</p>
          </v-window-item>
          <v-window-item>
            <p>Memória de cálculo</p>
          </v-window-item>
        </v-window>
      </v-card>
    </v-col>
    <v-col class="mr-3 d-flex flex-column">
      <v-card class="p-3">
        <v-card-subtitle class="pb-0">
          <v-alert
            color="blue"
            dense
            text
            v-if="false"
            class="v-label-input mt-1 mb-0"
          >
            Anexos cujo upload não foi concluído são desconsiderados no processo
            de salvamento.
          </v-alert>
          <v-alert
            dense
            text
            type="error"
            v-if="false"
            class="v-label-input mt-1 mb-0"
          >
            Anexos com erro são exibidos aqui, mas são desconsiderados no
            processo de salvamento.
          </v-alert>
        </v-card-subtitle>
        <v-card-subtitle class="pb-1">
          <uploader-v
            v-model="projeto.anexo"
            v-slot="{ selectFiles }"
            @input="anexosChanged"
          >
            <v-btn
              class="mx-auto d-block px-2"
              dense
              text
              @click="selectFiles()"
            >
              <v-icon left>mdi-paperclip</v-icon>
              Clique aqui para anexar arquivos
            </v-btn>
          </uploader-v>
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text class="py-0">
          <template v-if="!projeto.anexo.length">
            <v-alert
              border="left"
              class="v-label-input"
              color="primary"
              dense
              outlined
              text
              type="info"
            >
              Não existem anexos.
            </v-alert>
          </template>
          <v-card
            elevation="0"
            class="mb-2"
            v-for="(anexo, akey) in projeto.anexo"
            :key="akey"
          >
            <v-progress-linear
              :color="anexo.error ? 'error' : 'secondary'"
              :value="anexo.percent"
              :striped="anexo.uploading"
              height="2em"
            >
              <v-card-text class="p-2">
                <v-row
                  justify="space-between"
                  align="center"
                  no-gutters
                  class="py-2 pl-2 pr-0"
                >
                  <span
                    class="v-label-input text-truncate"
                    :style="{ width: anexo.uploading ? '260px' : '290px' }"
                  >
                    <v-icon left>
                      {{
                        anexo.error
                          ? "mdi-file-document-alert"
                          : "mdi-file-document"
                      }}
                    </v-icon>
                    <span :title="getAnexoTitle(anexo)">
                      {{ anexo.error || anexo.nome }}
                    </span>
                  </span>
                  <span v-if="anexo.uploading" class="v-label-input">
                    {{ anexo.percent }}%
                  </span>
                  <v-btn
                    v-if="isClient || !idProjeto"
                    :disabled="!canEdit"
                    small
                    icon
                    @click="projeto.anexo.splice(akey, 1)"
                  >
                    <v-icon small>mdi-close-circle-outline</v-icon>
                  </v-btn>
                  <v-btn v-else small icon :href="anexo.url" target="_blank">
                    <v-icon small>mdi-download</v-icon>
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-progress-linear>
          </v-card>
        </v-card-text>
      </v-card>

      <v-card class="action-buttons-wrap">
        <v-card-actions class="px-5 py-4 flex-wrap justify-content-center">
          <v-btn class="mr-2" color="primary" depressed @click="goBack()">
            Cancelar
          </v-btn>
          <v-btn class="mr-2" color="secondary" depressed @click="doSave()">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Alocacao from "./forms/alocacao.vue";
import BPForm from "./forms/businessplan.vue";
import Discovery from "./forms/discovery.vue";
import OutrasDespesas from "./forms/outras-despesas.vue";
import Participantes from "./forms/participantes.vue";
import Roadmap from "./forms/roadmap.vue";
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";

export default {
  // props: {},
  components: {
    Alocacao,
    BPForm,
    Discovery,
    OutrasDespesas,
    "uploader-v": () => import("@/components/uploader-v.vue"),
    Participantes,
    Roadmap,
  },
  // mixins: [],
  // directives: {},
  data() {
    return {
      currentStep: 0,
      projeto: {
        anexo: []
      },
      anexos: [],
      opts: {
        statusRubrica: [
          {
            id: 1,
            value: "Ativo"
          },
          {
            id: 0,
            value: "Inativo"
          }
        ]
      },
      dadosBP: {}
    };
  },
  computed: {
    ...mapGetters(["clientId"]),
    businessPlanId() {
      return this.$route.params.id || this.dadosBP?.id || null;
    },
    // customResource() {
    //   const resource = this.apiResource("/v1/captacao/tipoRubricas");
    //   return {
    //     ...resource,
    //     get(...params) {
    //       return resource.get(params).then(result => {
    //         return result;
    //       });
    //     }
    //   };
    // },
    resource() {
      return this.apiResource(
        `/v1/ativacaoped/${this.clientId}/business-plans`
      );
    },
    tabs() {
      return [
        "Business plan",
        "Discovery",
        "Roadmap",
        "Participantes",
        "Alocação",
        "Outras despesas",
        "VPL",
        "Memória de cálculo"
      ];
    }
  },
  // filters: {},
  created() {
    if (this.businessPlanId) {
      this.doLoad(this.businessPlanId);
    }
  },
  // mounted() {},
  // updated() {},
  // destroyed() {},
  methods: {
    anexosChanged: function(nextAnexos) {
      this.anexos = cloneDeep(nextAnexos);
    },
    async doLoad(id) {
      try {
        const response = await this.resource.get(id);
        this.dadosBP = response.data;
      } catch (error) {
        this.notify(error);
      }
    },
    goBack() {
      this.$router.push({
        name: "ativacaoped-projetos"
      });
    },
    async doSave() {
      try {
        const response = await this.resource.save(this.dadosBP);

        if (this.businessPlanId) {
          this.doLoad(this.businessPlanId);
        } else {
          this.$router.push({
            name: "ativacaoped-projetos-form-edicao",
            params: { id: response.data.id }
          });
        }
      } catch (error) {
        this.notify(error);
      }
    }
  },
  watch: {
    clientId(next, old) {
      if (next !== old) {
        this.$router.push({
          name: "ativacaoped-projetos"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.action-buttons-wrap {
  position: sticky;
  bottom: 12px;
  margin-top: auto;
}

.v-card__actions > {
  div,
  .v-btn {
    padding: 0;
    margin: 0 0.25rem;
    width: calc(50% - 1rem);
  }

  div .v-btn {
    width: 100%;
  }
}

.v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn {
  margin-left: 9px !important;
}

.tab {
  display: flex;
  opacity: 0.8;
  padding: 0 1rem;

  &.active {
    opacity: 1;
    border-bottom: 5px solid var(--v-table-header-base);
  }
}
</style>
