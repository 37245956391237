<template>
  <v-row class="ml-n4 mr-0 mt-5">
    <Field
      v-for="(field, index) of fields.businessPlan"
      class="pl-4"
      :colSize="field.colSize"
      :key="`business-plan-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
    ></Field>
    <v-col :cols="12">
      <h3>Informações da iniciativa</h3>
    </v-col>
    <Field
      v-for="(field, index) of fields.iniciativa"
      class="pl-4"
      :colSize="field.colSize"
      :key="`iniciativa-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
    ></Field>
    <v-col :cols="12">
      <h3>IAS38 - Framework</h3>
    </v-col>
    <v-col :cols="12">
      <h4>Recurso controlado</h4>
    </v-col>
    <Field
      v-for="(field, index) of fields.recursoControlado"
      class="pl-4"
      :colSize="field.colSize"
      :key="`recurso-controlado-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
    ></Field>
    <v-col :cols="12">
      <h4>Benefício econômico futuro</h4>
    </v-col>
    <Field
      v-for="(field, index) of fields.beneficioEconomicoFuturo"
      class="pl-4"
      :colSize="field.colSize"
      :key="`beneficio-economico-futuro-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
    ></Field>
    <v-col :cols="12">
      <h4>Não-monetário</h4>
    </v-col>
    <Field
      v-for="(field, index) of fields.naoMonetario"
      class="pl-4"
      :colSize="field.colSize"
      :key="`nao-monetario-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
    ></Field>
    <v-col :cols="12">
      <h4>Identificável</h4>
    </v-col>
    <Field
      v-for="(field, index) of fields.identificavel"
      class="pl-4"
      :colSize="field.colSize"
      :key="`identificavel-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
    ></Field>
    <v-col :cols="12">
      <h4>Sem substância física</h4>
    </v-col>
    <Field
      v-for="(field, index) of fields.semSubstancia"
      class="pl-4"
      :colSize="field.colSize"
      :key="`sem-substancia-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
    ></Field>
    <v-col :cols="12">
      <h3>IAS38 - CAPEX</h3>
    </v-col>
    <v-col :cols="12">
      <h4>Existência de mercado</h4>
    </v-col>
    <Field
      v-for="(field, index) of fields.existenciaMercado"
      class="pl-4"
      :colSize="field.colSize"
      :key="`existencia-mercado-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
    ></Field>
    <v-col :cols="12">
      <h4>Intenção de concluir</h4>
    </v-col>
    <Field
      v-for="(field, index) of fields.intencaoConcluir"
      class="pl-4"
      :colSize="field.colSize"
      :key="`intencao-concluir-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
    ></Field>
    <v-col :cols="12">
      <h4>Mensuração confiável</h4>
    </v-col>
    <Field
      v-for="(field, index) of fields.mensuracaoConfiavel"
      class="pl-4"
      :colSize="field.colSize"
      :key="`mensuracao-confiavel-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
    ></Field>
    <v-col :cols="12">
      <h3>Responsáveis</h3>
    </v-col>
    <Field
      v-for="(field, index) of fields.responsaveis"
      class="pl-4"
      :colSize="field.colSize"
      :key="`responsaveis-${index}`"
      :label="field"
      :opts="opts"
      :valid.sync="field.valid"
      v-model="formData[field.key]"
    ></Field>
  </v-row>
</template>

<script>
import { isEqual } from "lodash";
import { mapGetters } from 'vuex';
import { StatusBusinessPlanEnum, StatusBusinessPlanLabels } from '@/core/enums/ativacaoped';

const DEFAULT_FORM_DATA = {
  ativo_controle: false,
  ativo_destinado_uso: "Interno",
  ativo_forma_separacao: "Alugar",
  ativo_moeda_caixa: false,
  ativo_produto_servico_semelhante: false,
  ativo_substancia_fisica: "Possui",
  beneficio_economico_futuro: "Geração de Receita",
  engenharia_valor: false,
  impacto_financeiro: "Receita Nova",
  iniciativa_impacto: "Produto Novo",
  iniciativa_participantes: "People",
  medicao_kpi: "Mensal",
  mercado_comercializacao: false,
  participantes_registro_horas: false,
  respnsaveis_participantes_registro_horas: false,
  status: 1,
  vida_util: 2
};

export default {
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  components: {
    Field: () => import("@/components/input-v.vue")
  },
  // mixins: [],
  // directives: {},
  data() {
    // Gera opções de 2 a 10 anos
    const vidaUtil = new Array(8)
      .fill(0)
      .map((_, index) => ({ id: index + 2, value: `${index + 2} anos` }));
    return {
      formData: {},
      opts: {
        beneficiosEconomicosFuturo: [
          {
            value: "Geração de Receita"
          },
          {
            value: "Redução de Custo"
          }
        ],
        destinosUso: [
          {
            value: "Interno"
          },
          {
            value: "Externo"
          }
        ],
        emails: [],
        formasSeparacao: [
          {
            value: "Alugar"
          },
          {
            value: "Vender"
          },
          {
            value: "Transferir"
          },
          {
            value: "Trocar"
          },
          {
            value: "Licenciar"
          }
        ],
        impactos: [
          {
            value: "Adição de Feature"
          },
          {
            value: "Automatização de Processos"
          },
          {
            value: "Evolução Incremental"
          },
          {
            value: "Plataforma Nova"
          },
          {
            value: "Produto Novo"
          }
        ],
        impactosFinanceiros: [
          {
            value: "Custo — Evitação (Cost Aoidance)"
          },
          {
            value: "Custo — Redução (Saving)"
          },
          {
            value: "Receita Incremental"
          },
          {
            value: "Receita Nova"
          }
        ],
        medicaoKpi: [
          {
            value: "Mensal"
          },
          {
            value: "Trimestral"
          },
          {
            value: "Semestral"
          },
          {
            value: "Anual"
          }
        ],
        participantes: [
          {
            value: "People"
          },
          {
            value: "No People"
          },
          {
            value: "People & No People"
          }
        ],
        responsaveis: [
          {
            id: 1,
            value: "Em definição do BP"
          }
        ],
        status: [
          {
            id: StatusBusinessPlanEnum.DEFINICAO,
            value: StatusBusinessPlanLabels.DEFINICAO
          },
          {
            id: StatusBusinessPlanEnum.APROVACAO,
            value: StatusBusinessPlanLabels.APROVACAO
          },
          {
            id: StatusBusinessPlanEnum.ANDAMENTO,
            value: StatusBusinessPlanLabels.ANDAMENTO
          },
          {
            id: StatusBusinessPlanEnum.PRE_AMORTIZACAO,
            value: StatusBusinessPlanLabels.PRE_AMORTIZACAO
          },
          {
            id: StatusBusinessPlanEnum.AMORTIZACAO,
            value: StatusBusinessPlanLabels.AMORTIZACAO
          },
        ],
        substanciasFisicas: [
          {
            value: "Não possui"
          },
          {
            value: "Possui"
          }
        ],
        switchOpts: [
          {
            id: false,
            value: "Não"
          },
          {
            id: true,
            value: "Sim"
          }
        ],
        vidaUtil
      }
    };
  },
  computed: {
    ...mapGetters(['clientId']),
    fields() {
      return {
        beneficioEconomicoFuturo: [
          {
            key: "kpi",
            name: "KPI",
            type: this.$fieldTypes.TEXTAREA
          },
          {
            key: "medicao_kpi",
            name: "Periodicidade de Medição KPI",
            type: this.$fieldTypes.SELECT,
            rel: { to: "medicaoKpi", key: "value", name: "value" },
            colSize: 6
          },
          {
            key: "beneficio_economico_futuro",
            name: "Benefício Econômico Futuro por meio de",
            type: this.$fieldTypes.SELECT,
            rel: {
              to: "beneficiosEconomicosFuturo",
              key: "value",
              name: "value"
            },
            colSize: 6
          },
          {
            key: "impacto_financeiro",
            name: "Impacto Financeiro na Companhia",
            type: this.$fieldTypes.SELECT,
            rel: { to: "impactosFinanceiros", key: "value", name: "value" },
            colSize: 6
          },
          {
            key: "beneficio_economico_monetario",
            name:
              "Quais os benefícios econômicos (MONETÁRIOS) serão gerados em favor da empresa com o ativo construído na iniciativa? Além disso, quais benefícios tecnológicos, mercadológicos, produtividade e qualidade são gerados com a construção desse ativo?",
            type: this.$fieldTypes.TEXTAREA,
            colSize: 12
          }
        ],
        businessPlan: [
          {
            key: "nome",
            name: "Nome da iniciativa",
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: "required" }],
            colSize: 12
          },
          {
            key: "vida_util",
            name: "Vida útil do projeto",
            type: this.$fieldTypes.SELECT,
            rel: { to: "vidaUtil", key: "id", name: "value" },
            colSize: 6
          },
          {
            key: "status",
            name: "Status",
            type: this.$fieldTypes.SELECT,
            rel: { to: "status", key: "id", name: "value" },
            colSize: 6
          },
          {
            key: "data_inicio",
            name: "Data de início",
            type: this.$fieldTypes.DATE,
            colSize: 6
          },
          {
            key: "data_fim",
            name: "Data de término",
            type: this.$fieldTypes.DATE,
            colSize: 6
          }
        ],
        existenciaMercado: [
          {
            key: "ativo_produto_servico_semelhante",
            name:
              "Há produtos ou serviços semelhantes no mercado ao Ativo resultante do escopo da Iniciativa?",
            type: this.$fieldTypes.SWITCH,
            rel: { to: "switchOpts", key: "id", name: "value" },
            colSize: 6
          },
          {
            key: "ativo_produto_servico_semelhante_desc",
            name:
              "Se houver, cite os principais produtos e/ou serviços semelhantes hoje no mercado",
            type: this.$fieldTypes.TEXTAREA,
            colSize: 6
          },
          {
            key: "mercado_comercializacao",
            name: "Há mercado para comercialização do produto ou serviço?",
            type: this.$fieldTypes.SWITCH,
            rel: { to: "switchOpts", key: "id", name: "value" },
            colSize: 6
          },
          {
            key: "potenciais_consumidores",
            name:
              "Se houver, cite os potenciais clientes e/ou consumidores do Ativo.",
            type: this.$fieldTypes.TEXTAREA,
            colSize: 6
          }
        ],
        identificavel: [
          {
            key: "ativo_forma_separacao",
            name:
              "Independente da destinação dada ao ativo, a entidade consegue identificar e separar o ativo gerado pelo projeto da seguinte forma",
            type: this.$fieldTypes.SELECT,
            rel: { to: "formasSeparacao", key: "value", name: "value" },
            colSize: 12
          }
        ],
        iniciativa: [
          {
            key: "iniciativa_escopo",
            name: "O que é a iniciativa? Qual é o escopo da iniciativa?",
            type: this.$fieldTypes.TEXTAREA,
            colSize: 6
          },
          {
            key: "iniciativa_inovacoes",
            name: "Quais as inovações apresentadas pela iniciativa?",
            type: this.$fieldTypes.TEXTAREA,
            colSize: 6
          },
          {
            key: "iniciativa_impacto",
            name: "Impacto inovação na companhia",
            type: this.$fieldTypes.SELECT,
            rel: { to: "impactos", key: "value", name: "value" },
            colSize: 6
          },
          {
            key: "iniciativa_participantes",
            name: "Participantes da iniciativa",
            type: this.$fieldTypes.SELECT,
            rel: { to: "participantes", key: "value", name: "value" },
            colSize: 6
          },
          {
            key: "iniciativa_riscos_barreiras",
            name:
              "Quais as barreiras e riscos atreladas a execução da iniciativa?",
            type: this.$fieldTypes.TEXTAREA,
            colSize: 6
          },
          {
            key: "iniciativa_relacoes_tecnologia",
            name: "Relação de tecnologias e o seu emprego na iniciativa",
            type: this.$fieldTypes.TEXTAREA,
            colSize: 6
          },
          {
            key: "iniciativa_beneficios_economicos",
            name:
              "Quais os benefícios econômicos (MONETÁRIOS) serão gerados em favor da XP com o ativo construído na iniciativa?",
            type: this.$fieldTypes.TEXTAREA,
            colSize: 6
          }
        ],
        intencaoConcluir: [
          {
            key: "engenharia_valor",
            name:
              "A iniciativa passa por Engenharia de Valor dentro do seu devido processo de priorização na esteira de desenvolvimento da Companhia?",
            type: this.$fieldTypes.SWITCH,
            rel: { to: "switchOpts", key: "id", name: "value" },
            colSize: 6
          },
          {
            key: "relacao_id_epicos_portifolios",
            name: "Relação ID de Épico de Portfólios ou Épicos",
            type: this.$fieldTypes.TEXTAREA,
            colSize: 6
          }
        ],
        mensuracaoConfiavel: [
          {
            key: "participantes_registro_horas",
            name:
              "Hoje, TODA a equipe executora (citada na aba Participantes) realiza o registro de horas no VSTS em nível de PBI e/ou task?",
            type: this.$fieldTypes.SWITCH,
            rel: { to: "switchOpts", key: "id", name: "value" },
            colSize: 12
          },
          {
            key: "respnsaveis_participantes_registro_horas",
            name:
              "Os líderes técnicos da iniciativa se comprometem e responsabilizam pelo acompanhamento do registro de horas dos colaboradores no VSTS e a garantir que todos os participantes do projeto atendarão de forma adequada ao processo",
            type: this.$fieldTypes.SWITCH,
            rel: { to: "switchOpts", key: "id", name: "value" },
            colSize: 12
          }
        ],
        naoMonetario: [
          {
            key: "ativo_moeda_caixa",
            name:
              "O ativo que é representado por unidades de moeda mantidas em caixa?",
            type: this.$fieldTypes.SWITCH,
            rel: { to: "switchOpts", key: "id", name: "value" },
            colSize: 6
          },
          {
            key: "ativo_destinado_uso",
            name: "Utilização do Ativo será destinado para uso",
            type: this.$fieldTypes.SELECT,
            rel: { to: "destinosUso", key: "value", name: "value" },
            colSize: 6
          }
        ],
        recursoControlado: [
          {
            key: "ativo_controle",
            name:
              "O ativo gerado pelo projeto, sendo materiais, dispositivos, produtos, processos, sistemas ou serviços novos ou substancialmente aprimorados, serão de controle da empresa?",
            type: this.$fieldTypes.SWITCH,
            rel: { to: "switchOpts", key: "id", name: "value" },
            colSize: 12
          },
          {
            key: "formas_restricao",
            name:
              "A empresa consegue evidenciar que possui capacidade de restringir o acesso de terceiros ao benefícios gerados pelo ativo (ex: propriedade intelectual)? Quais são as formas de restrição?",
            type: this.$fieldTypes.TEXTAREA
          }
        ],
        responsaveis: [
          {
            key: "email_tecnologia",
            name: "Líder de Tecnologia da iniciativa",
            type: this.$fieldTypes.SELECT,
            rel: { to: "emails", key: "id", name: "value" },
            colSize: 12
          },
          {
            key: "email_produtos",
            name: "Líder de Produtos da iniciativa",
            type: this.$fieldTypes.SELECT,
            rel: { to: "emails", key: "id", name: "value" },
            colSize: 12
          },
          {
            key: "email_negocios",
            name: "Líder de Negócios da iniciativa",
            type: this.$fieldTypes.SELECT,
            rel: { to: "emails", key: "id", name: "value" },
            colSize: 12
          }
        ],
        semSubstancia: [
          {
            key: "ativo_substancia_fisica",
            name: "Quanto aos elementos físicos, o ativo gerado pelo projeto",
            type: this.$fieldTypes.SELECT,
            rel: { to: "substanciasFisicas", key: "value", name: "value" },
            colSize: 12
          }
        ]
      };
    },
    resource() {
      return this.apiResource("/v1/captacao/tipoRubricas");
    }
  },
  created() {
    this.setInternalData(this.data);
    this.getEmails();
  },
  methods: {
    async getEmails() {
      try {
        const { get } = this.apiResource(`/v1/ativacaoped/${this.clientId}/responsaveis/selecao`);
        const response = await get();

        if (Array.isArray(response)) {
          this.opts.emails = response;
          return;
        }

        this.opts.emails = [];
      } catch (error) {
        this.notify(error);
        this.opts.emails = [];
      }
    },
    setInternalData(value) {
      if (!value) {
        return;
      }

      this.formData = { ...DEFAULT_FORM_DATA, ...value };
    }
  },
  watch: {
    data(value) {
      if (!isEqual(this.formData, value)) {
        this.setInternalData(value);
      }
    },
    formData(value) {
      this.$emit("update:data", value);
    }
  }
};
</script>

<style lang="scss" scoped>
h3,
h4 {
  font: 600 0.875rem Poppins;
  margin-left: 0.75rem;
  padding-bottom: 0;
}

h3 {
  font-size: 1.25rem;
}
</style>
